import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import {container} from "react-bootstrap"
import Layout from "../../components/layout"

export default function ForKidsAndParents({data}) {
  return (
    <Layout>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy policy for kids and parents | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/about" />
      </Helmet>



      <h3>Privacy policy (for kids and parents)</h3>

    <p>Your privacy is very important to us, and we take it seriously. Please read our Privacy Policy below and let us know if you have any questions or other feedback regarding it. For additional details please refer also to our <a href="/policy/data-policy/" target="_blank">Data Policy</a>.</p>


    <strong>PRIVACY POLICY</strong>

    <p>Personal information: In our ad-supported apps we use the IP address and mobile advertising ID of your device in order to show non-personalized ads. Please refer to our <a href="/policy/data-policy/" target="_blank">data policy</a> for details, including how to opt out of such usage.</p>

    <p>Data retention: If we do happen to obtain any personal information, including emails or phone numbers perhaps obtained through support requests or other correspondence, it will be treated with the strictest confidentiality. Any such personal information will never be shared with any other entities. Any such personal information will not be retained beyond the resolution of the support request or other correspondence.</p>

    <p>Age limitations: We do not knowingly contact or do business with children under 13 years of age. If a parent or guardian becomes aware that a child under the age of 13 has attempted to contact and/or do business with us, please advise us by email at cheeplus@protonmail.ch so that we may rectify the situation.</p>

    <p>Anonymous usage information: Our apps and websites may collect anonymous usage information to help improve the quality of our services and applications.</p>

    <p>Internet permissions: Our apps do typically require internet permissions, for the purpose of providing links to the app store entry of our products and to collect anonymous usage information (see above).</p>

    <p>In-app purchases: Our apps may contain in-app purchases (IAP’s).</p>

    <p>Advertising: Some of our apps contain third-party advertising. We may occasionally promote other products of our own within our apps. We may occasionally cross-promote the products of other affiliated developers that offer similar products and share similar values as expressed on their own published privacy policies.</p>

    <p>Social media: Our apps do not interact with any social media sites. We may provide links to our own social media sites for the purpose of providing support and product information.</p>


    <strong>QUESTIONS CONCERNING OUR PRIVACY POLICY</strong>

    <p>If you have any question concerning our Privacy Policy please <a href="/contact/" target="_blank">contact us</a>.</p>



    <strong>CHANGES TO OUR PRIVACY POLICY</strong>

    <p>We reserve the right to modify our Privacy Policy at any time simply by posting such modification on this site and without any other notification. Any such modification will be effective immediately upon posting on this site. It is your responsibility to review our Privacy Policy from time to time to ensure that you continue to agree with all of its terms.</p>



    <p>===LAST UPDATED===</p>
    <p>The Privacy Policy was last updated on 31/08/2020.</p>




    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
